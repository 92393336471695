import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css"; // Import CSS file for styling

const data = [
  { title: "Low Risk", description: "Capital preservation with fixed ROI properties.", icon: "📊", risk: 2, return: 3 },
  { title: "Mid Risk", description: "Balanced approach with mixed assets.", icon: "⚖️", risk: 3, return: 4 },
  { title: "High Risk", description: "Aggressive asset allocation for high returns.", icon: "🔥", risk: 5, return: 5 },
];


const Carousel = () => {
  const [progress, setProgress] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    afterChange: (current) => setProgress(((current + 1) / data.length) * 100),
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1, arrows: false } },
    ],
  };

  return (
    <div className="carousel-container">
      <h2 className="heading">upgrade your life. <br /> bit by bit.</h2>
      <Slider {...settings}>
        {data.map((item, index) => (
          
          <div key={index} className="card">
            <div className="card-icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <div className="risk-section">
              <span>Relative risk</span>
              <div className="dots">
                {Array(5).fill(0).map((_, i) => (
                  <span key={i} className={`dot ${i < item.risk ? "filled" : ""}`}></span>
                ))}
              </div>
            </div>
            <div className="return-section">
              <span>Relative return</span>
              <div className="dots">
                {Array(5).fill(0).map((_, i) => (
                  <span key={i} className={`dot ${i < item.return ? "filled" : ""}`}></span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Progress Bar at Bottom */}
      <div className="progress-slider">
        <div className="progress-track">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;