// import React from 'react';
// import './WealthComparison.css';

// const WealthComparison = () => {
//   return (
//     <div className="comparison-container">
//       <h3 className="compare-text">COMPARE FOR YOURSELF</h3>
//       <h1 className="title">The Path To Generational Wealth Experience,<br /> In The Palm Of Your Hand</h1>
//       <div className="table-header">
//         <span></span>
//         <span className='lgg'>WINNOW</span>
//         <span>Stock Trading Apps</span>
//         <span>Private Wealth Managers</span>
//       </div>
//       <div className="table-section">
//         <h4>INVESTMENT OPTIONS</h4>
//         {renderRow("Strategic Property Investment", "Tax-optimized real estate assets, including luxury, rental, and commercial properties.", true, false, true)}
//         {renderRow("High-Growth Real Estate Funds", "Actively managed REITs and real estate-backed securities for long-term returns.", true, false, true)}
//         {renderRow("Passive Property Income", "Low-cost, tax-efficient rental property portfolios.", true, false, true)}
//         {renderRow("Alternative Wealth Strategies", "Invest in real estate-backed private equity and land development projects.", true, false, true)}
//         {renderRow("Diversified Asset Management", "Blend real estate investments with traditional equities and funds.", true, true, true)}
//       </div>
//       <div className="table-section">
//         <h4>ADVISORY SERVICES</h4>
//         {renderRow("Tailored Real Estate Portfolios", "Custom-built property investment strategies to maximize returns.", true, false, true)}
//         {renderRow("Market Intelligence & Insights", "Access to top-tier real estate research and property trend analysis.", true, false, true)}
//         {renderRow("Exclusive Investment Advisory", "Personalized guidance on wealth-building through real estate.", true, false, true)}
//       </div>
//       <div className="table-section">
//         <h4>EXCLUSIVE BENEFITS</h4>
//         {renderRow("Optimized Tax Strategies", "Hassle-free property tax planning and deductions.", true, false, false)}
//         {renderRow("Priority Access to Off-Market Deals", "Insider opportunities in high-value real estate markets.", true, false, false)}
//         {renderRow("Concierge Real Estate Services", "Dedicated support for property acquisition, leasing, and management.", true, false, true)}
//       </div>
//       <p className="disclaimer">
//         The comparison above is for informational purposes only. Features listed for other platforms or services are not exhaustive, may vary, and are subject to change.
//       </p>
//     </div>
//   );
// };

// const renderRow = (title, description, winnow, stockApps, wealthManagers) => {
//   return (
//     <div className="table-row">
//       <div className="left-column">
//       <span
//       className="row-title"
//       style={{
//         color: "var(--www-titan-com-alabaster, var(--color-grey-97, #F8F8F8))",
//         fontFamily: "var(--font-family-Font-1, Inter)",
//         fontSize: "16.875px",
//         fontStyle: "normal",
//         fontWeight: "var(--font-weight-400, 400)",
//         lineHeight: "var(--line-height-22_5, 22.5px)",
//       }}
//     >
//       {title}
//     </span>
//         <span
//         className="row-description small-text"
//         style={{
//           color: "var(--www-titan-com-dusty-gray, var(--color-grey-61, #9C9C9C))",
//           fontFamily: "var(--font-family-Font-1, Inter)",
//           fontSize: "12.086px",
//           fontStyle: "normal",
//           fontWeight: "var(--font-weight-400, 400)",
//           lineHeight: "var(--line-height-18_2, 18.2px)",
//         }}
//       >
//         {description}
//       </span>
//       </div>
//       <span className="yellow-text">{winnow ? "●" : "-"}</span>
//       <span>{stockApps ? "●" : "-"}</span>
//       <span>{wealthManagers ? "●" : "-"}</span>
//     </div>
//   );
// };

// export default WealthComparison;



import React from 'react';
import './WealthComparison.css';

const WealthComparison = () => {
  return (
    <div className="comparison-container">
      <h3 className="compare-text">COMPARE FOR YOURSELF</h3>
      <h1 className="title">The Path To Generational Wealth Experience,<br /> In The Palm Of Your Hand</h1>
      <div className="table-header">
        <span></span>
        <span className='lgg'>WINNOW</span>
        <span>Stock Trading Apps</span>
        <span>Private Wealth Managers</span>
      </div>
      <div className="table-section">
        <h4>INVESTMENT OPTIONS</h4>
        {renderRow("Strategic Property Investment", "Tax-optimized real estate assets, including luxury, rental, and commercial properties.", true, false, true)}
        {renderRow("High-Growth Real Estate Funds", "Actively managed REITs and real estate-backed securities for long-term returns.", true, false, true)}
        {renderRow("Passive Property Income", "Low-cost, tax-efficient rental property portfolios.", true, false, true)}
        {renderRow("Alternative Wealth Strategies", "Invest in real estate-backed private equity and land development projects.", true, false, true)}
        {renderRow("Diversified Asset Management", "Blend real estate investments with traditional equities and funds.", true, true, true)}
      </div>
      <div className="table-section">
        <h4>ADVISORY SERVICES</h4>
        {renderRow("Tailored Real Estate Portfolios", "Custom-built property investment strategies to maximize returns.", true, false, true)}
        {renderRow("Market Intelligence & Insights", "Access to top-tier real estate research and property trend analysis.", true, false, true)}
        {renderRow("Exclusive Investment Advisory", "Personalized guidance on wealth-building through real estate.", true, false, true)}
      </div>
      <div className="table-section">
        <h4>EXCLUSIVE BENEFITS</h4>
        {renderRow("Optimized Tax Strategies", "Hassle-free property tax planning and deductions.", true, false, false)}
        {renderRow("Priority Access to Off-Market Deals", "Insider opportunities in high-value real estate markets.", true, false, false)}
        {renderRow("Concierge Real Estate Services", "Dedicated support for property acquisition, leasing, and management.", true, false, true)}
      </div>
      <p className="disclaimer">
        The comparison above is for informational purposes only. Features listed for other platforms or services are not exhaustive, may vary, and are subject to change.
      </p>
    </div>
  );
};

const renderRow = (title, description, winnow, stockApps, wealthManagers) => {
  return (
    <div className="table-row">
      <div className="left-column">
        <span className="row-title">{title}</span>
        <span className="row-description">{description}</span>
      </div>
      <span className="yellow-text">{winnow ? "●" : "-"}</span>
      <span>{stockApps ? "●" : "-"}</span>
      <span>{wealthManagers ? "●" : "-"}</span>
    </div>
  );
};

export default WealthComparison;