import React from "react";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* Background Video */}
      <video autoPlay loop muted className="background-video">
        <source
          src="/images/8146c10e3a738c205b64d86720c239b7fcced8d7.mp4"
          type="video/mp4"
        />
      </video>

      {/* Navbar */}
      <header className="navbar">
        <img src="/images/White logo 1.png" alt="WINNOW Logo" className="logo" />
        <button className="menu-btn">☰</button>
      </header>

      {/* Hero Section */}
      <section className="hero-section">
        <h2 className="hero-title">
          one stop shop for <span><br />Passive Income</span>
        </h2>
        <p className="hero-subtitle">
          WINNOW is an investment platform that enables the trustworthy to make financial progress
        </p>
      </section>

      {/* Features Section */}
      <section className="features1" style={{ textAlign: "center" }}>
        <div className="feature-card">
          <img src= "/images/ix_user-management-filled.png" alt="Professional Management" className="feature-icon" />
          <h3>Professional Management</h3>
          <p>Explore curated opportunities spanning risk profiles, private equity & more</p>
        </div>
        
        <div className="feature-card">
          <img src="/images/ic_baseline-real-estate-agent.png" alt="Premium Assets" className="feature-icon" />
          <h3>Premium Assets</h3>
          <p>Explore curated opportunities spanning real estate, private capital & equity</p>
        </div>

        <div className="feature-card">
          <img src="/images/Vector.png" alt="Board Diversification" className="feature-icon" />
          <h3>Board Diversification</h3>
          <p>We enable you to invest in various markets, portfolios with high ROI</p>
        </div>
      </section>
    </div>
  );
};
export default LandingPage;