// import React from "react";
// import "./AdvisorSection.css";

// const AdvisorSection = () => {
//   return (
//     <div className="advisor-card">
//       <div className="advisor-content">
//         {/* Left Side Content */}
//         <div className="advisor-left">
//           <button className="advisor-badge">Help on demand</button>
//           <h2 className="advisor-title">
//           Get Guidance From <br /> Professional Advisors
//           </h2>
//           <p className="advisor-description">
//           Book one-on-one sessions <br /> and group calls with our financial advisors.
//           </p>
//         </div>

//         {/* Right Side Content */}
//         <div className="advisor-right">
//           <div className="advisor-image-container">
//             <img
//               src="images/Group 1597883160 (1).png"
//               alt="Advisors"
//               className="advisor-image"
//             />
//             <p className="advisor-footer">
//             Our advisors and analysts come from experience:
//           </p>
//           </div>
//           {/* <div className="advisor-names">
//             <p>Advisor 1</p>
//             <p>Advisor 2</p>
//             <p>Advisor 3</p>
//             <p>Advisor 4</p>
//           </div> */}
          
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdvisorSection;




import React from "react";
import "./AdvisorSection.css";

const AdvisorSection = () => {
  return (
    <div className="advisor-card">
      <div className="advisor-content">
        {/* Left Side Content */}
        <div className="advisor-left">
          <button className="advisor-badge">Help on demand</button>
          <h2 className="advisor-title">Get Guidance From <br /> Professional Advisors</h2>
          <p className="advisor-description">
            Book one-on-one sessions <br /> and group calls with our financial advisors.
          </p>
        </div>

        {/* Right Side Content */}
        <div className="advisor-right">
          <div className="advisor-image-container">
            <img
              src="images/Group 1597883160 (1).png"
              alt="Advisors"
              className="advisor-image"
            />
          </div>
          {/* <div className="advisor-names">
            <div className="name-row">
              <span>Advisor 1</span>
              <span>Advisor 2</span>
            </div>
            <div className="name-row">
              <span>Advisor 3</span>
              <span>Advisor 4</span>
            </div>
          </div> */}
          <p className="advisor-footer">Our advisors and analysts come from experience</p>
        </div>
      </div>
    </div>
  );
};

export default AdvisorSection;