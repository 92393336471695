import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2 className="footer-logo">WINNOW</h2>
        </div>

        <div className="footer-section">
          <h4>Investor Services</h4>
          <ul>
            <li>Overview</li>
            <li>Property Management</li>
            <li>Asset Management</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Investment Solutions</h4>
          <ul>
            <li>For Institutions</li>
            <li>Overview</li>
            <li>Capital Management ↗</li>
            <li>For Individuals & Partners</li>
            <li>Explore Properties ↗</li>
            <li>Sell Your Property ↗</li>
            <li>Agents & Brokers ↗</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Technology</h4>
          <ul>
            <li>Overview</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li>About Us</li>
            <li>Our Impact</li>
            <li>Research & Insights</li>
            <li>News & Press</li>
            <li>Careers</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Social</h4>
          <ul>
            <li>LinkedIn ↗</li>
            <li>Facebook ↗</li>
            <li>X ↗</li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Contact Support</h4>
          <p>800-466-4XX6</p>
          <p>support@winnow.com</p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-links">
          <span>Privacy Policy</span>
          <span>Terms & Conditions</span>
          <span>Do Not Sell</span>
          <span>Broker Licenses</span>
        </div>
        <p>© 2025 Winnow Inc.</p>
      </div>

      <div className="footer-disclosure">
        <p>
          Disclosure: This material is for informational and educational
          purposes only. It is not real estate, investment, or financial
          planning advice. Winnow is not your advisor or agent unless expressly
          specified by Winnow Realty, LLC...
        </p>
      </div>

      <button className="contact-button">Contact Us</button>
    </footer>
  );
};

export default Footer;