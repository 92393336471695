import React from "react";
import "./OurStory.css"; // Importing external CSS

const OurStory = () => {
  return (
    <div className="story-container">
      <h2 className="story-title1">Our Story</h2>
      <div className="story-image"></div>
      <p className="story-subtext">
        We set out to build the real estate investment firm we always wanted, brick by brick.
      </p>
      <div className="story-text">
        <p className="gradient-text">Real estate investments were originally transferred</p>
        <p className="gradient-text">Ownership of a single land — to a community of flat owners</p>
        <p className="gradient-text">Creating generational wealth in the process.</p>
        <p className="gradient-text bold-text">3 generations later, we are here.</p>
        <p className="gradient-text mid-text">
          Redefining the game by making real estate an <span className="highlight">investible asset class</span> 
          for retail investors alike.
        </p>
      </div>
    </div>
  );
};

export default OurStory;