import Carousel from './components/Carousel';
import HeroSection from './components/HeroSection';
import InvestPage from './components/InvestPage';
import LandingPage from './components/LandingPage';
import Portfolio from './components/Portfolio';
import TextBlock from './components/TextBlock';
import WealthComparison from './components/WealthComparison';
import WealthTeamSection from "./components/WealthTeamSection";
import AdvisorSection from "./components/AdvisorSection";
import BenefitSection from "./components/BenefitSection";
import ProofSection from "./components/ProofSection";
import FAQ from "./components/FAQ";
import ContactSection from "./components/ContactSection";
import RewardsPage from './components/VideoPage';
import OurStory from './components/OurStory';
import Footer from './components/Footer';

function App() {
  return (
    <div>
    <LandingPage/>
    <TextBlock/>
    <Portfolio/>
    <InvestPage/>
    <HeroSection/>
    <Carousel/>
    <RewardsPage/>
    <WealthTeamSection />
    <WealthComparison/>
    <OurStory/>
    <AdvisorSection/>
    <BenefitSection/>
    <FAQ/>
    <ProofSection/>
    <ContactSection/>
    <Footer/>
    </div> 
    
  );
}

export default App;