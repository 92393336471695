import React from "react";
import "./HeroSection.css"; // Import CSS file

const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1 className="hero-title1">
          This is the new standard <br /> we’re building.
        </h1>
        <p className="hero-subtitle">
          A platform where clarity meets confidence, and every decision leads to growth.  
          <span className="hero-highlight">
            Welcome to Winnow. Invest boldly, build wisely.
          </span>
        </p>
      </div>
      <div className="hero-graphic">
      </div>
    </div>
  );
};
export default HeroSection;