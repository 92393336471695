import React from 'react';
import "./Portfolio.css"; // Import the CSS file

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <div className="video-frame">
        <img src="/images/Container (1).png" alt="Portfolio" className="portfolio-image" />
      </div>

      <div className="portfolio-content">
        <h3 className="portfolio-title">Level Up Your Portfolio <br/>Diversification</h3>
        <p className="member-text">
          If you're a WINNOW member, you're already a step ahead. Every<br />
          experience you unlock takes you higher up the pedestal.
        </p>
      </div>
    </div>
  );
};

export default Portfolio;