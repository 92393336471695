import React from 'react';
import "./RewardsPage.css"; // Import the CSS file

const RewardsPage = () => {
  return (
    <div className="rewards-container">
      <div className="video-section">
        <video autoPlay loop muted className="background-video">
          <source src="/images/cba341c816b5be4559ebe0dc6832a8e7ad62120a.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="rewards-content">
        <h3 className="rewards-title">Feel the Odds Fall in Your Favor</h3>
        <p className="rewards-text">
          Unlock cashback, exclusive rewards from select brands, and special<br />
          access to curated products and experiences.
        </p>
      </div>
    </div>
  );
};

export default RewardsPage;
