import React from "react";
import "./InvestPage.css"; // Import CSS file

const properties = [
  {
    id: 1,
    image: "/images/Modern 3 floors renovated villa on a High Number Location, picture 1.png",
    title: "Palm Jumeirah Ready Signature Villas",
    price: "INR 8,00,00,000",
    views: 30,
    followers: 0,
    beds: 6,
    baths: 6,
    size: "6,081 sq. ft.",
  },
  {
    id: 2,
    image: "/images/Modern 3 floors renovated villa on a High Number Location, picture 1.png",
    title: "Palm Jumeirah Ready Signature Villas",
    price: "INR 8,00,00,000",
    views: 30,
    followers: 0,
    beds: 6,
    baths: 6,
    size: "6,081 sq. ft.",
  },
  {
    id: 3,
    image: "/images/Modern 3 floors renovated villa on a High Number Location, picture 1.png",
    title: "Palm Jumeirah Ready Signature Villas",
    price: "INR 15,00,00,000",
    views: 30,
    followers: 0,
    beds: 6,
    baths: 6,
    size: "6,089 sq. ft.",
  },
];

const InvestPage = () => {
  return (
    <div className="invest-container">
      <h1 className="invest-title">
        Invest In Fractions Of <br /> Rental Properties
      </h1>

      <div className="property-grid">
        {properties.map((property) => (
          <div key={property.id} className="property-card">
            <div className="property-image">
              <img src={property.image} alt={property.title} />
              <span className="featured-badge">Featured</span>
            </div>
            <div className="property-details">
              <p className="property-meta">{property.views} Views • {property.followers} Followers</p>
              <h2 className="property-title">{property.title}</h2>
              <p className="property-price">{property.price}</p>
              <p className="property-info">
                🛏 {property.beds} beds • 🛁 {property.baths} baths • 📏 {property.size}
              </p>
              <button className="follow-button">Follow</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestPage;