import React from "react";
import "./WealthTeamSection.css"; // Import the CSS file

const WealthTeamSection = () => {
  return (
    <div className="wealth-section">
      <h4 className="section-title">WHAT'S INCLUDED</h4>
      <h1 className="main-heading">
        Your Complete Wealth Team, <br /> For Free.
      </h1>
      <p className="subtext">(Traditional advisors typically charge 1-1.8% for less)</p>

      <div className="features">
        <div className="feature">
          <h3 className="feature-title">Investment Memos</h3>
          <p className="feature-text">
            Automated index investing that tracks global markets – the foundation of a diversified portfolio. Simple, efficient, effective.
          </p>
        </div> 
        <div className="feature">
          <h3 className="feature-title">Active Management</h3>
          <p className="feature-text">
            Our investment team's conviction-driven ideas, actively managed in pursuit of long-term growth. We research, pick, and manage positions for you.
          </p>
        </div>
        <div className="feature">
          <h3 className="feature-title">Performance Tracking</h3>
          <p className="feature-text">
            Your cash should work as hard as you do. We automatically move it to our highest available after-tax yield for you.
          </p>
        </div>
        <div className="feature">
          <h3 className="feature-title">Ask An Advisor</h3>
          <p className="feature-text">
            Access to alternative investments, including options traditionally reserved for the ultra-wealthy: venture capital, private credit, real estate, and crypto. Managed by notable Wall Street firms, now available to you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WealthTeamSection;
