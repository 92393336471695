import React, { useState } from "react";
import "./FAQ.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 

const faqData = [
  {
    question: "What are the key differences between a REIT and Winnow?",
    answer: `
    - Public vs. Private Investment**: REITs are publicly traded instruments, whereas Winnow allows investors to become shareholders in a Private Limited Company that owns the property.
    - Yields: In a REIT, the yield varies based on your entry price, while with Winnow, yields are fixed.
    - Volatility: REITs are subject to stock market fluctuations; Winnow's asset valuations are more stable.
    - Payouts: REITs distribute at least 90% of net cash flow on a scheduled basis, whereas Winnow pays out monthly.
    - Non-Revenue Generating Assets: REITs can invest up to 10% in under-construction assets, whereas all of Winnow’s assets are revenue-generating.
    - Exiting Assets: A REIT cannot sell assets held for less than 3 years, but Winnow allows selling post the lock-in period with shareholder approval.
    `,
  },
  {
    question: "What if something goes wrong with Winnow? Is my investment safe?",
    answer: `
    Your investment is in the form of equity shares and compulsorily convertible debentures in a Private Limited Company (SPV), which owns the asset. Even if Winnow is not functional, your ownership and control of the asset remain secure.
    `,
  },
  {
    question: "What is the investment process?",
    answer: `
    - Step 1: Create a Winnow account and complete KYC verification.
    - Step 2: Choose an opportunity and commit funds.
    - Step 3: Sign a binding Expression of Interest (EOI) and pay a 10% token advance.
    - Step 4: Once fully funded, investors transfer the remaining balance.
    - Step 5: Receive equity shares and CCDs in the SPV that owns the asset.
    `,
  },
  {
    question: "What happens if a property does not get fully funded?",
    answer: "If a property does not meet its funding target, all committed funds are reimbursed to investors’ verified bank accounts.",
  },
  {
    question: "Do I have to travel to the location of the property or your office to invest?",
    answer: "No, all required documentation is signed digitally using a reputed e-signature provider.",
  },
  {
    question: "What if I want to withdraw my initial token advance?",
    answer: "If you wish to withdraw your initial token advance, a termination fee applies as per the Expression of Interest terms.",
  },
  {
    question: "Is there any lock-in on my investment?",
    answer: "Yes, there is an initial 1-year lock-in. After that, you are free to sell your holdings.",
  },
  {
    question: "What are Winnow's fees?",
    answer: `
    - **1% annual management fee** (deducted from monthly payouts).
    - **20% performance fee** on gains above a 10% IRR.
    - **2% facilitation fee** for resale transactions (exempt in certain cases).
    `,
  },
  {
    question: "Will there be a management fee when the property is not tenanted?",
    answer: "No, there will be no management fee charged if the property is not tenanted.",
  },
  {
    question: "When is my investment process complete?",
    answer: "Your investment is complete once the opportunity is fully funded and private placement is done in the SPV.",
  },
  {
    question: "When & how will I receive my returns?",
    answer: "Returns are paid monthly as interest on debentures and transferred to your bank account before the 10th working day of every month.",
  },
  {
    question: "Who manages the tenant and the asset?",
    answer: "Winnow handles all aspects of asset and tenant management on behalf of investors.",
  },
  {
    question: "How will I be updated on the performance of my investments?",
    answer: "Investment performance can be monitored through the online dashboard, with NAV updates every six months.",
  },
  {
    question: "How can I exit my investment?",
    answer: `
    - **Asset Sale**: If 75% of investors vote to sell, the property is liquidated.
    - **Private Sale**: You can sell your holdings to another investor.
    - **Resale Market**: You can list your holdings for sale on the Winnow platform.
    `,
  },
  {
    question: "What are the tax implications on my investments?",
    answer: `
    - **Rental Payouts**: Taxed as "Income from Other Sources."
    - **Capital Gains Tax**: STCG applies if sold before 24/36 months, LTCG applies after that.
    `,
  },
  {
    question: "How will the returns of NRIs be taxed?",
    answer: `
    - **Interest on debentures** is taxed at 20.8% TDS.
    - NRIs can claim DTAA benefits using a Tax Residency Certificate (TRC).
    `,
  },
  
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">FAQ</h2>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div key={index} className={`faq-item ${openIndex === index ? "open" : ""}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              {openIndex === index ? <FaChevronUp className="faq-icon" /> : <FaChevronDown className="faq-icon" />}
            </div>
            {openIndex === index && (
              <div className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;