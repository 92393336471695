import React from "react";
import "./TextBlock.css";

const TextBlock = () => {
  return (
    <div className="container">
      <p className="small-text">NOT EVERYONE MAKES IT IN</p>
      <div className="content-box">
        <p><span className="bold">WINNOW</span> is built on</p>
        <p>TRUST</p>
        <p>Smart investments deserve more—growth, security, and freedom.</p>
        <p>We’re redefining real estate investing, making decisions seamless and opportunities limitless.</p>
        <p style={{ fontWeight: "bold", color: "#f7b500" }}>Invest smarter.</p>
<p style={{ fontWeight: "bold", color: "#f7b500" }}>Build stronger.</p>
      </div>
    </div>
  );
};

export default TextBlock;