import React from "react";
import "./ContactSection.css";
import { ArrowRight } from "lucide-react";

const ContactSection = () => {
  return (
    <div className="contact-container">
      <div className="content-wrapper">
        <div className="contact-header">
          <div className="indicator"></div>
          <span className="contact-text">CONTACT</span>
        </div>
        <h1 className="contact-title">Ready to get started with Winnow?</h1>
        <button className="contact-btn">
          Get In Touch <ArrowRight size={18} className="icon" />
        </button>
      </div>
    </div>
  );
};

export default ContactSection;
