import React from "react";
import "./ProofSection.css";

const ProofSection = () => {
  return (
    <div className="proof-section">
      <div className="content">
        <div className="proof-text">
          <p className="trusted">TRUSTED BY 15M MEMBERS</p>
          <h1 className="proof-heading">
            the proof <br /> writes itself
          </h1>
        </div>
        <div className="ratings">
          <div className="rating">
            <div className="stars">★★★★★</div>
            <p className="rating-value">4.8<span>/5</span></p>
            <p className="platform">GOOGLE</p>
          </div>
          <div className="rating">
            <div className="stars">★★★★★</div>
            <p className="rating-value">4.8<span>/5</span></p>
            <p className="platform">REDDIT</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProofSection;
