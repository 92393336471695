import React from "react";
import "./BenefitSection.css";

const BenefitSection = () => {
  return (
    <div className="benefit-container">
      <div className="benefit-content">
        <h1 className="benefit-title">
          not everyone <br /> gets the benefit
        </h1>
        <div className="benefit-list">
          <ul>
            <li>
              <span className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.0142 12.924L17.9362 27.8268L8.26367 18.1542L10.7723 15.6455L17.7673 22.6406L28.3477 10.584L31.0142 12.924Z" fill="#FBC76C"/>
                </svg>
              </span>
              Historically outperformed Equities
            </li>
            <li>
              <span className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.0142 12.924L17.9362 27.8268L8.26367 18.1542L10.7723 15.6455L17.7673 22.6406L28.3477 10.584L31.0142 12.924Z" fill="#FBC76C"/>
                </svg>
              </span>
              Natural inflation hedge
            </li>
            <li>
              <span className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.0142 12.924L17.9362 27.8268L8.26367 18.1542L10.7723 15.6455L17.7673 22.6406L28.3477 10.584L31.0142 12.924Z" fill="#FBC76C"/>
                </svg>
              </span>
              Lower correlation to public markets
            </li>
          </ul>
          <ul>
            <li>
              <span className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.0142 12.924L17.9362 27.8268L8.26367 18.1542L10.7723 15.6455L17.7673 22.6406L28.3477 10.584L31.0142 12.924Z" fill="#FBC76C"/>
                </svg>
              </span>
              Income and growth opportunities
            </li>
            <li>
              <span className="tick-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M31.0142 12.924L17.9362 27.8268L8.26367 18.1542L10.7723 15.6455L17.7673 22.6406L28.3477 10.584L31.0142 12.924Z" fill="#FBC76C"/>
                </svg>
              </span>
              Tax efficient
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BenefitSection;
